
import {
  Component, Prop, Watch, Ref,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import MediaPlan from '@/models/MediaPlan'
import DataForm from '@/components/DataForm/DataForm.vue'
import { MediaPlanProposalActions, OptionalOpportunityAgencyIds } from '@/models/interface/Common'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import moment from 'moment'
import MediaPlanItem from '@/models/MediaPlanItem'
import IconAction from '@/components/IconAction/IconAction.vue'
import { isUndefined, clone as _clone } from 'lodash'
import WebMessage from '@/models/WebMessage'
import BigNumber from 'bignumber.js'
import LayoutModule from '@/store/LayoutModule'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Company from '@/models/Company'
import { read, utils } from 'xlsx'
import SelectOption from '@/models/interface/SelectOption'
import DropArea from '@/components/DropArea/DropArea.vue'
import Task from '@/models/Task'
import OrderDocumentParser from '@/models/OrderDocumentParser/OrderDocumentParser'
import { EventBus } from '@/plugins/eventBus'
import Opportunity from '@/models/Opportunity'
import OpportunityForm from '@/pages/Opportunity/components/OpportunityForm.vue'
import numeral from 'numeral'
import BatchEditMediaPlanItems from './components/BatchEditMediaPlanItems.vue'
import formData from './form'
import MediaPlanSchedules from './components/MediaPlanSchedules.vue'

@Component({
  components: {
    Widget,
    DataForm,
    BatchEditMediaPlanItems,
    SelectPicker,
    IconAction,
    DropArea,
    OpportunityForm,
    MediaPlanSchedules,
  },
})
export default class MediaPlanEdit extends ViewModel {
  @Ref() private drop_area!: DropArea

  @Ref() private theForm!: DataForm

  @Prop({ default: null })
  public id!: string

  private ref: string = 'MediaPlans'

  private busy = false

  public opportunity: any = {}

  public modal: boolean = false

  public spots_modal: boolean = false

  public download_confim: boolean = false

  public generate_trade: boolean = false

  private clearRequired: boolean = false

  public formFields: any = formData

  private agency: Company = new Company()

  public import_errors: string[] = []

  private confirming_order: boolean = false

  public import_status: string = ''

  public get trade_percentage(): number {
    /* if (moment(this.model.start_at).startOf('day').isBefore(moment('2022-12-26').startOf('day'))) {
      return 15
    } */
    return 13.04
  }

  public trade_generator_mode: string = 'one_to_one' // one_to_one, single, one_per_month

  public model = new MediaPlan()

  private container: boolean = true

  public get optionalOpportunityAgencyIds(): string[] {
    return OptionalOpportunityAgencyIds
  }

  private get view_mode() {
    const { view_mode } = getModule(LayoutModule)
    return view_mode
  }

  private get title() {
    return this.id ? 'Edit Media Plan' : 'Create Media Plan'
  }

  private get form() {
    return formData
  }

  private get is_confirm() {
    const { query } = this.$route
    return !isUndefined(query.confirm) || this.confirming_order
  }

  public get proposalOptions() {
    return MediaPlanProposalActions
  }

  public previewOpportunity() {
    this.$bvModal.show('preview-opportunity')
  }

  @Watch('model.agency_id')
  public onChangeAgency() {
    const { query } = this.$route
    if (
      !this.model.agency_id
      || this.model.agency_id === this.agency.id
      || this.id
      || (query.from && typeof query.from === 'string')
    ) {
      return
    }

    Company.find(this.model.agency_id).then(agency => {
      if (!agency) {
        return
      }
      this.model.agency = agency
      if (
        this.model.isLinear
        && (!agency.agency_commission || agency.agency_commission.model === 'none')
      ) {
        this.model.formAgencyCommission = 15
        this.model.formAgencyCommissionModel = 'percentage'
        return
      }

      this.model.metadata.invoice.template_id = agency.billing_info.invoice_template
      this.model.metadata.invoice.delivery_driver = agency.billing_info.invoice_delivery_driver

      if (!agency.agency_commission) return
      this.agency = agency
      this.model.formAgencyCommission = Number(agency.agency_commission.value)
      this.model.formAgencyCommissionModel = agency.agency_commission.model
    })
  }

  @Watch('model.advertiser_id')
  public onChangeAdvertiser() {
    const { query } = this.$route
    if (
      !this.model.advertiser_id
      || this.model.advertiser_id === this.agency.id
      || this.id
      || (query.from && typeof query.from === 'string')
    ) {
      return
    }

    Company.find(this.model.advertiser_id).then(advertiser => {
      if (!advertiser) {
        return
      }
      this.model.advertiser = advertiser
    })
  }

  // @Watch('model.salesforce_opportunity_id')
  public onChangeOpportunityID() {
    let valid = false
    if (typeof this.model.salesforce_opportunity_id === 'string') {
      valid = /[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}/.test(this.model.salesforce_opportunity_id)
    }

    if (!valid) {
      this.loading = false
      return
    }

    this.loading = true
    this.model
      .getOpportunityData()
      .then(response => {
        this.opportunity = response.data.result
        if (!this.id) {
          this.model.name = this.opportunity.name
          this.model.sales_rep_id = this.opportunity.sales_rep_id
          this.model.formType = this.opportunity.type
          this.loading = false
        }
      })
      .catch(error => {
        this.loading = false
      })
  }

  private created() {
    this.loading = true
    const { query } = this.$route

    if (query.ref && typeof query.ref === 'string') {
      this.ref = query.ref
    }

    const default_values: any = {
      targets: {
        vcr: 80,
        ctv: 45,
        live: 0,
      },
      items: {
        rate: null,
        notes: null,
      },
    }

    if (query.rate) {
      default_values.items.rate = Number(query.rate)
    }

    // vcr=0.95&ctv=1&live=1
    if (
      typeof query.vcr !== 'undefined'
      && query.ctv !== 'undefined'
      && query.live !== 'undefined'
    ) {
      const vcr = numeral(new BigNumber(Number(query.vcr)).toNumber()).format('0%')
      const ctv = numeral(new BigNumber(Number(query.ctv)).toNumber()).format('0%')
      const live = numeral(new BigNumber(Number(query.live)).toNumber()).format('0%')

      default_values.items.notes = `VCR: ${vcr} | CTV: ${ctv} | LIVE: ${live}`
      default_values.targets.vcr = Number(query.vcr) * 100
      default_values.targets.ctv = Number(query.ctv) * 100
      default_values.targets.live = Number(query.live) * 100
      default_values.targets.audience = query.audience == '1'
      default_values.targets.foot_traffick = query.foot_traffick == '1'
    }

    this.model.default_values = default_values

    if (this.id) {
      let promises: any[] = [MediaPlan.find(this.id)]

      if (query.cash_tmp_source || query.trade_tmp_source) {
        promises.push(MediaPlan.processOrderFiles(query.cash_tmp_source, query.trade_tmp_source))
      }
      Promise.allSettled(promises).then((responses: any) => {
        if (responses[0].value instanceof MediaPlan) {
          this.model = responses[0].value

          // Update File Metadata
          if (query.cash_tmp_source) {
            this.model.model_files_meta.push({
              id: this.model.metadata.cash_io ?? null,
              name: query.cash_name,
              source_name: query.cash_source_name,
              description: query.cash_description,
              size: query.cash_size,
              type: query.cash_type,
              tmp_source: query.cash_tmp_source,
            })
          }

          // Trade
          if (query.trade_tmp_source) {
            this.model.model_files_meta.push({
              id: this.model.metadata.trade_io ?? null,
              name: query.trade_name,
              source_name: query.trade_source_name,
              description: query.trade_description,
              size: query.trade_size,
              type: query.trade_type,
              tmp_source: query.trade_tmp_source,
            })
          }

          this.setAccountManager()
          if (!isUndefined(query.confirm)) {
            this.model.status = 'pending_documents'
          }
          if (responses[1]) {
            this.model.status = 'confirmed'
            this.model.loadOrderData(responses[1].value.data.result)
          }
          this.loading = false
        }
      })
    } else if (query.from && typeof query.from === 'string') {
      MediaPlan.find(query.from).then(media_plan => {
        if (media_plan instanceof MediaPlan) {
          const base = new MediaPlan()

          this.model = media_plan.clone()
          this.model.metadata = { ...base.metadata }
          this.model.id = ''
          this.model.opportunity_id = ''
          this.model.status = 'draft'
          this.model.metrics = {}

          this.model.line_items = this.model.line_items.map(line_item => {
            line_item = line_item.clone()
            line_item.id = ''
            line_item.metrics = {}
            return line_item
          })
          this.loading = false

          this.createFromTask()
        }
      })
    } else if (query.sf_opporttunity && typeof query.sf_opporttunity === 'string') {
      this.model.salesforce_opportunity_id = query.sf_opporttunity

      this.setAccountManager()

      this.createFromTask()
    } else {
      this.setAccountManager()

      this.loading = false

      this.createFromTask()
    }
  }

  public async createFromTask() {
    if (this.$route.query && this.$route.query.task && !this.$route.params.id) {
      this.loading = true
      // @ts-ignore
      let task = await Task.find(this.$route.query.task)

      if (
        !task
        || !task.id
        || !task.owner_model_id
        || task.owner_model_type !== 'App\\Models\\Opportunity'
      ) {
        this.loading = false
        this.busy = false
        return
      }

      let opportunity = await Opportunity.find(task.owner_model_id)

      if (!opportunity || !opportunity.id) {
        this.loading = false
        this.busy = false
        return
      }

      this.model.name = opportunity.name

      this.model.agency_id = opportunity.agency_id

      this.model.advertiser_id = opportunity.advertiser_id

      this.model.opportunity_id = opportunity.id

      this.model.opportunity = opportunity

      this.model.sales_management_id = opportunity.sales_management_id

      this.model.sales_rep_id = opportunity.sales_rep_id

      this.loading = false
      this.busy = false
    }
  }

  public cancel() {
    this.$router.back()
  }

  public showBatchModal() {
    this.modal = true
  }

  public showGenerateTrade() {
    // Check data first
    let has_errors = false
    let message = ''

    if (this.model.elegible_trade_spots === 0 && this.trade_generator_mode === 'one_per_month') {
      has_errors = true
      message = 'Invalid Media Plan fligth dates! Please make sure that the plan has at least one full week in a month before generating a trade item.'
    }

    if (!has_errors) {
      this.model.line_items.forEach(line_item => {
        if (line_item.impressions == 0 || line_item.total_spots == 0) {
          has_errors = true
          line_item._showDetails = true
          message = 'Please make sure that all Line Items have impressions and at least one spot before generating trade item.'
        }
      })
    }

    if (!has_errors) {
      this.generate_trade = true
    } else {
      WebMessage.error(message)
    }
  }

  public confirmGenerateTrade() {
    // one_to_one, single, one_per_month
    if (this.trade_generator_mode === 'one_per_month') {
      this.generateMultipleTrade()
    } else if (this.trade_generator_mode === 'single') {
      this.generateSingleTrade()
    } else {
      this.generateOneToOneTrade()
    }
  }

  public generateOneToOneTrade() {
    let trade_items: MediaPlanItem[] = []
    let impressions = +this.model.bnImpressions.times(this.trade_percentage / 100).integerValue()
    let rate = _clone(this.model.gross_rate)
    let elegible_spots = _clone(this.model.elegible_trade_spots)
    let new_items = true

    if (this.model.has_trade_item) {
      trade_items = this.model.line_items.filter(
        line_item => line_item.metadata.order_type === 'trade',
      )

      // Restore Impressions Back to Regular Line Items
      if (trade_items.length > 0) {
        new_items = false
        let cash_impressions = this.model.impressions - +impressions
        this.model.line_items.forEach((line_item: MediaPlanItem) => {
          if (line_item.metadata.order_type !== 'trade') {
            line_item.formSpotImpressions += +line_item.bnImpressions
              .div(cash_impressions)
              .times(impressions)
              .div(line_item.total_spots)
              .integerValue()
          }
        })
      }
    }

    // Build Trade Items, runs on timeout to avoid blocking the UI
    setTimeout(() => {
      let i = 0
      this.model.line_items
        .filter(line_item => line_item.metadata.order_type !== 'trade')
        .forEach((item: MediaPlanItem) => {
          // Calculate Trade Item Impressions
          let trade_impressions = +item.bnImpressions
            .times(this.trade_percentage / 100)
            .integerValue()

          // Fetch next Trade Item, if not found, create a new one
          let trade_item: MediaPlanItem | undefined
          if (trade_items[i++]) {
            trade_item = this.model.line_items.find(
              line_item => line_item.id === trade_items[i - 1].id,
            )
          }
          if (!trade_item) {
            trade_item = this.model.addLineItem()
          }

          // Set Trade Item Properties
          trade_item.name = `${item.name} - SSL 2 Trade`
          trade_item.metadata.order_type = 'trade'
          trade_item.metadata.program_name = 'SSL 2'
          trade_item.metadata.demo_target = _clone(item.metadata.demo_target)
          // trade_item.media_package_id = '9619a1c2-4423-4d9a-bd71-8b7a051d7868'
          trade_item.media_package_id = item.media_package_id
          if (
            item.media_package
            && item.media_package.special_packages
            && item.media_package.special_packages.length
          ) {
            const found = item.media_package.special_packages.find(pkg => pkg.type === 'trade')

            if (found) {
              trade_item.media_package_id = found.media_package_id
            }
          }

          trade_item.metadata.flight_time.start_at = moment(
            `${trade_item.start_at} 23:00:00`,
          ).format('HH:mm:ss')

          trade_item.notes = _clone(item.notes)
          trade_item.start_at = _clone(item.start_at)
          trade_item.end_at = _clone(item.end_at)
          trade_item.metadata.spots = _clone(item.metadata.spots)
          trade_item.metadata.days = _clone(item.metadata.days)
          trade_item.gross_rate = _clone(item.gross_rate)
          trade_item._showDetails = true
          trade_item.formImpressions = trade_impressions
          trade_item.creative_length = _clone(item.creative_length)

          // Remove Impressions from Cash Line Items
          item.formImpressions -= trade_impressions
        })
      WebMessage.success(
        `Trade Items ${
          new_items ? 'created' : 'updated'
        }! Please review all Line Items as Impressions & Cost might have changed due to number rounding.`,
      )
    }, 350)
  }

  public generateMultipleTrade() {
    let trade_items: MediaPlanItem[] = []
    let impressions = +this.model.bnImpressions.times(this.trade_percentage / 100).integerValue()
    let rate = _clone(this.model.gross_rate)
    let elegible_spots = _clone(this.model.elegible_trade_spots)
    let new_items = true

    if (this.model.has_trade_item) {
      trade_items = this.model.line_items.filter(
        line_item => line_item.metadata.order_type === 'trade',
      )

      // Restore Impressions Back to Regular Line Items
      if (trade_items.length > 0) {
        new_items = false
        let cash_impressions = this.model.impressions - +impressions
        this.model.line_items.forEach((line_item: MediaPlanItem) => {
          if (line_item.metadata.order_type !== 'trade') {
            line_item.formSpotImpressions += +line_item.bnImpressions
              .div(cash_impressions)
              .times(impressions)
              .div(line_item.total_spots)
              .integerValue()
          }
        })
      }
    }

    // Build Spot Dates
    let trade_dates: any = []
    let pointer = moment(this.model.start_at)

    let included_months: string[] = []
    while (pointer.isBefore(this.model.end_at)) {
      if (!included_months.includes(pointer.format('YYMM'))) {
        let end = pointer.clone().add(1, 'weeks')

        if (pointer.month() === end.month()) {
          trade_dates.push({
            start_at: pointer.format('YYYY-MM-DD'),
            end_at: end.format('YYYY-MM-DD'),
          })

          included_months.push(pointer.format('YYMM'))
        }
      }

      pointer.add(1, 'week')
    }

    let i = 0
    for (; i < elegible_spots; i++) {
      let trade_item: MediaPlanItem | undefined
      if (trade_items[i]) {
        trade_item = this.model.line_items.find(line_item => line_item.id === trade_items[i].id)
      }

      if (!trade_item) {
        trade_item = this.model.addLineItem()
      }
      trade_item.name = `Trade ${i + 1}`
      trade_item.metadata.order_type = 'trade'
      trade_item.metadata.program_name = 'SSL 2'
      trade_item.metadata.demo_target = 0
      trade_item.media_package_id = '9619a1c2-4423-4d9a-bd71-8b7a051d7868'
      trade_item.notes = '100% Live Games'
      trade_item.start_at = trade_dates[i].start_at
      trade_item.end_at = trade_dates[i].end_at
      trade_item.gross_rate = rate
      trade_item._showDetails = true
    }

    for (; i < trade_items.length; i++) {
      trade_items[i].impressions = 0
    }

    // Remove Impressions from Cash Line Items
    this.model.line_items
      .filter(line_item => line_item.metadata.order_type !== 'trade')
      .forEach((item: MediaPlanItem) => {
        item.formSpotImpressions = +item.bnImpressions
          .times(new BigNumber(this.trade_percentage).div(100).negated().plus(1))
          .div(item.total_spots)
          .integerValue()
      })
    setTimeout(() => {
      this.model.line_items
        .filter(line_item => line_item.metadata.order_type === 'trade')
        .forEach((item: MediaPlanItem) => {
          item.metadata.spots = [1]
          item.formSpotImpressions = +new BigNumber(impressions).div(elegible_spots).integerValue()
        })

      WebMessage.success(
        `Trade Items ${
          new_items ? 'created' : 'updated'
        }! Please review all Line Items as Impressions & Cost might have changed due to number rounding.`,
      )
    }, 100)
  }

  public generateSingleTrade() {
    let trade_item: MediaPlanItem | undefined
    let impressions = +this.model.bnImpressions.times(this.trade_percentage / 100).integerValue()
    let start_at = _clone(this.model.start_at)
    let end_at = _clone(this.model.end_at)
    let rate = _clone(this.model.gross_rate)
    let new_item = true

    if (this.model.has_trade_item) {
      trade_item = this.model.line_items.find(
        line_item => line_item.metadata.order_type === 'trade',
      )

      // Restore Impressions Back to Regular Line Items
      if (trade_item) {
        new_item = false
        let i = trade_item.bnImpressions
        let cash_impressions = this.model.impressions - +impressions
        this.model.line_items.forEach((item: MediaPlanItem) => {
          if (trade_item && item.number !== trade_item.number) {
            item.formSpotImpressions += +item.bnImpressions
              .div(cash_impressions)
              .times(impressions)
              .div(item.total_spots)
              .integerValue()
          }
        })
      }
    }

    if (!trade_item) {
      trade_item = this.model.addLineItem()
      trade_item.metadata.order_type = 'trade'
      trade_item.name = 'Trade'
      trade_item.metadata.program_name = 'SSL 2'
      trade_item.metadata.demo_target = 0
      trade_item.media_package_id = '9619a1c2-4423-4d9a-bd71-8b7a051d7868'
      trade_item.notes = '100% Live Games'
    }

    trade_item.formStartAt = start_at
    trade_item.formEndAt = end_at

    // Set Spots
    let months: string[] = []
    let pointer = moment(this.model.start_at)
    let week_index = 0
    while (
      pointer.isBefore(this.model.end_at)
      && trade_item.total_spots < this.model.elegible_trade_spots
    ) {
      if (!months.includes(`${pointer.month()}${pointer.year()}`)) {
        let can_have_spot = false
        let elegible_line_items = this.model.line_items.filter(line_item =>
          pointer.isBetween(line_item.start_at, line_item.end_at, undefined, '[]'))

        for (let key in elegible_line_items) {
          let line_item = elegible_line_items[key]

          let index = pointer.diff(line_item.start_at, 'weeks')

          if (line_item.metadata.spots[index] >= 1) {
            can_have_spot = true
            break
          }
        }

        if (can_have_spot) {
          trade_item.metadata.spots[week_index] = 1
          months.push(`${pointer.month()}${pointer.year()}`)
        }
      }

      pointer.add(1, 'week')
      week_index++
    }

    this.model.line_items.forEach((item: MediaPlanItem) => {
      item.formSpotImpressions = +item.bnImpressions
        .times(new BigNumber(this.trade_percentage).div(100).negated().plus(1))
        .div(item.total_spots)
        .integerValue()
    })

    trade_item.formSpotImpressions = +new BigNumber(impressions)
      .div(trade_item.total_spots)
      .integerValue()

    trade_item.formGrossRate = rate
    trade_item._showDetails = true

    WebMessage.success(
      `Trade Item ${
        new_item ? 'created' : 'updated'
      }! Please review all Line Items as Impressions & Cost might have changed due to number rounding.`,
    )
  }

  public weekPeriod(week: number) {
    const start = moment(this.model.start_at).clone()
    start.add(week, 'weeks')
    if (week > 0) {
      start.startOf('week').add(1, 'days')
    }
    return `${start.format('MM/DD')} - ${start
      .clone()
      .endOf('week')
      .add(1, 'days')
      .format('MM/DD')}`
  }

  public setAccountManager() {
    if (this.model && !this.model.account_manager_id) {
      this.model.account_manager_id = this.user.id
    }
  }

  public batchConfirm(data: any) {
    this.modal = false
    this.model.line_items = this.model.line_items.map(item => {
      data.selected.forEach((prop: string) => {
        // @ts-ignore
        if (prop.includes('flight_time')) {
          const path = prop.split('.')
          // @ts-ignore
          item[path[0]][path[1]][path[2]] = data.values[path[0]][path[1]][path[2]]
        } else if (prop.includes('metadata')) {
          const path = prop.split('.')
          // @ts-ignore
          item[path[0]][path[1]] = data.values[path[0]][path[1]]
        } else if (prop === 'impressions') {
          if (this.model.isLinear) {
            item.formSpotImpressions = data.values[prop]
          } else {
            item.formImpressions = data.values[prop]
          }
        } else if (prop === 'net_rate') {
          item.formNetRate = data.values[prop]
        } else if (prop === 'gross_rate') {
          item.formGrossRate = data.values[prop]
        } else if (prop === 'net_cost') {
          item.formNetCost = data.values[prop]
        } else if (prop === 'gross_cost') {
          item.formGrossCost = data.values[prop]
        } else if (prop === 'special_features') {
          item.special_features = data.values[prop]
        } else if (prop === 'dynamic_rate') {
          item.setDynamicRate(data.values.dynamic_rate_id)
        } else {
          // @ts-ignore
          item[prop] = data.values[prop]
        }
      })
      item._showDetails = false
      return item
    })
    setTimeout(() => {
      this.model.line_items = this.model.line_items.map(item => {
        item._showDetails = true
        return item
      })
    }, 500)
  }

  public onSubmit(model: MediaPlan) {
    this.busy = true
    model.download_on_save = false
    model
      .save()
      .then(response => {
        if (!response.data) {
          this.busy = false
          return
        }
        this.completeSave(response, true)
      })
      .catch(error => {
        this.busy = false
      })
  }

  private clearHomeFilters() {
    const { query } = this.$route
    if (query.from) {
      const system = getModule(SystemtModule)
      system.updateState({
        name: 'filters',
        type: 'media_plan',
        data: null,
      })
    }
  }

  public onSave(model: MediaPlan) {
    this.busy = true
    model.download_on_save = false
    model
      .save()
      .then(response => {
        if (!response.data) {
          this.busy = false
          return
        }
        this.completeSave(response, false)
      })
      .catch(error => {
        this.busy = false
      })
  }

  private completeSave(response: any, close: boolean) {
    this.clearHomeFilters()
    if (response.status == 200) {
      this.busy = false
      this.model = MediaPlan.toObject(response.data.result.media_plan)
      if (close) {
        if (this.model.id) {
          this.$router.push({ name: 'MediaPlanView', params: { id: this.model.id } })
        } else {
          this.$router.push({ name: this.ref })
        }
      }
    }
  }

  public confirmSaveDownload() {
    this.busy = true
    this.clearHomeFilters()

    this.model.save().then(response => {
      this.busy = false
      if (response.status == 200) {
        this.$router.push({ name: this.ref })
      }
    })
  }

  public onSubmitDownload(model: MediaPlan) {
    model.download_on_save = true
    this.model = model

    /*
    this.download_confim = true
    */

    return this.confirmSaveDownload()
  }

  private getWeekDates(start_at: string, week: number): string {
    const start = moment(start_at).add(week, 'weeks')
    return `${start.format('MM/DD/YYYY')} ~ ${start
      .endOf('week')
      .add(1, 'days')
      .format('MM/DD/YYYY')}`
  }

  private lineItemSpotIndex(line_item: MediaPlanItem, index: number): boolean | number {
    let pointer = moment(this.model.start_at).startOf('week').add(index, 'weeks').add(1, 'day')
    let start = moment(line_item.start_at).startOf('week').add(1, 'day')
    let end = moment(line_item.end_at).subtract(1, 'day').endOf('week').add(1, 'day')

    let ret: boolean | number = false

    if (pointer.isBetween(start, end, undefined, '[]')) {
      ret = 0
      while (pointer.isAfter(start)) {
        start.add(1, 'weeks')
        ret++
      }
    }

    return ret
  }

  // Register Ctrl+s to save
  public mounted() {
    document.onkeydown = e => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault()
        this.onSave(this.model)
      }
    }
  }

  /**
   * Clear events to prevent triggering the event outside the page
   */
  private beforeDestroy() {
    document.onkeydown = null
  }

  public openFilePicker() {
    this.drop_area.open()
  }

  public importOrderFile(files: FileList) {
    if (!files || files.length == 0) return

    if (files.length > 1) {
      WebMessage.error('You can only upload one order file at a time.')
      return
    }

    let file = files[0]

    if (file) {
      this.processOrderFile(file)
    }
  }

  private async processOrderFile(file: any) {
    this.import_status = 'Loading file...'
    this.$bvModal.show('import-loader')

    this.import_errors = []
    let reader = new FileReader()

    reader.onload = async (e: any) => {
      // get data
      let bytes = new Uint8Array(e.target.result)
      let file_name = file.name

      /* read workbook */
      let wb = read(bytes)

      const data = utils.sheet_to_json<any>(wb.Sheets[wb.SheetNames[0]])

      let company_result: SelectOption[] = []

      const new_plan = !this.model.id

      this.import_status = 'Extracting data...'

      const ea_messages = [
        'Hacking the matrix...',
        'Finding the meaning of life...',
        'Calculating pi...',
        'Finding the answer to the ultimate question of life, the universe, and everything...',
        'Warming up milk for the browser cookies...',
        'Waiting for the cat to bark...',
        'Waiting for the dog to meow...',
        'Waiting for the cat to exrtact the data...',
      ]

      // Has a 10% chance of showing an easter egg message
      const seed = Math.random()

      if (seed < 0.15) {
        this.import_status = ea_messages[Math.floor(Math.random() * ea_messages.length)]
      }

      let driver = null

      try {
        driver = OrderDocumentParser.parse(data)
      } catch (error) {
        this.$bvModal.hide('import-loader')
        WebMessage.error(
          'We were not able to validate the file. Please make sure you are uploading a Compulse Order.',
        )
        return
      }

      if (!new_plan) {
        if (!driver.order_id || !this.model.name.includes(driver.order_id)) {
          this.$bvModal.hide('import-loader')
          WebMessage.error(
            `The file you are trying to upload does not match the current media plan Order ID. (${
              this.model.metadata.client_data.order
            } vs ${driver.order_id || 'N/A'})`,
          )
          return
        }

        const missing_sli = this.model.line_items.some(item => !item.name.match(/(\d{6,10})/gims))

        if (missing_sli) {
          const coninue_process = await WebMessage.confirm(
            'Some line items are missing the SLI IDs, do you want to continue the import process? <br /><br />This might have some unexpected results.',
            'Missing SLI IDs',
            { okTitle: 'Import Anyway' },
          )

          if (!coninue_process) {
            this.$bvModal.hide('import-loader')
            WebMessage.warning('Import process cancelled!')
            return
          }
        }
      } else {
        // Only update core data if new plan
        if (driver.isAgency('Compulse')) {
          // Fetch agency
          company_result = await Company.searchOptions({
            search: '*Compulse*',
            type: 'agency',
          })
          if (
            company_result[0]
            && company_result[0].value
            && typeof company_result[0].value === 'string'
          ) {
            this.model.agency_id = company_result[0].value
          }
        } else {
          this.import_errors.push(
            'We were not able to identify the Agency, please assign it manually',
          )
        }

        const start_year = moment(data[0]['Start Date']).year()
        const end_year = moment(data[0]['End Date']).year()

        const year_name = start_year === end_year ? start_year : `${start_year}/${end_year}`

        this.model.metadata.client_data.order = driver.order_id
        this.model.metadata.client_data.name = driver.advertiser
        this.model.metadata.client_data.product = driver.product
        this.model.metadata.client_data.estimate = driver.estimate_id
        this.model.name = `Compulse Order_${driver.order_id}_${driver.advertiser} - ${year_name} - ${driver.geo_targeting}`
        this.model.contact_company = 'Compulse'
        this.model.contact_name = 'N/A'
        this.model.sales_management_id = '8e3e41ea-e22a-4f6f-8e34-a557b2fcae57'
        this.model.sales_rep_id = '8e3e41ea-e22a-4f6f-8e34-a557b2fcae57'

        // Fetch station
        company_result = await Company.searchOptions({
          search: `*${driver.station}*`,
          type: 'station',
        })
        if (
          company_result[0]
          && company_result[0].value
          && typeof company_result[0].value === 'string'
        ) {
          this.model.station_id = company_result[0].value
        } else {
          this.import_errors.push(
            `Station ${driver.station} not found, please create it manually and link with this media plan`,
          )
        }

        // Fetch advertiser
        company_result = await Company.searchOptions({
          search: `*${driver.advertiser}*`,
          type: 'advertiser',
        })
        if (
          company_result[0]
          && company_result[0].value
          && typeof company_result[0].value === 'string'
        ) {
          this.model.advertiser_id = company_result[0].value
        } else {
          this.import_errors.push(
            `Advertiser ${driver.advertiser} not found, please create it manually and link with this media plan`,
          )
        }
      }

      driver.lines.forEach(line => {
        let item = this.model.line_items.find((item, i) => {
          if (item.name.includes(line.id)) {
            return true
          }
          return false
        })

        if (!item) {
          this.model.addLineItem()
          item = this.model.line_items[this.model.line_items.length - 1]
        }

        let month = line.month

        // Match Zip Code
        item.metadata.targetting.include.zipcodes = line.zipcodes

        if (item.name === '') {
          item.name = `Live Games and Premium Sports - ${month} - ${line.geo_targeting} - ${line.id}`
        }
        if (item.notes === '') {
          item.notes = line.geo_targeting
        }

        if (
          item.formImpressions == line.impressions
          || item.start_at == line.start_at
          || item.end_at == line.end_at
        ) {
          this.import_errors.push('No updates found in file')
        }

        item.formImpressions = line.impressions
        item.start_at = line.start_at
        item.end_at = line.end_at

        if (line.start_time) {
          item.metadata.flight_time.start_at = line.start_time
        }

        if (line.end_time) {
          item.metadata.flight_time.end_at = line.end_time
        }

        if (item.formMediaPackageId == '' || item.formMediaPackageId == null) {
          item.formMediaPackageId = '9a66a1ca-1747-461d-a70a-61c41e9c5312'
        }

        if (item.formNetRate == 0) {
          item.formNetRate = this.model.agency?.default_rate || 0
        }
      })

      this.import_status = 'Generating PDF...'

      // Generate PDF
      driver.toPdf(wb, file_name, this.user.name, this.model.name, (blob: Blob) => {
        let file = new File([blob], `${file_name}.pdf`, { type: 'application/pdf' })
        this.import_status = 'Uploading PDF...'

        this.model.model_files_binary.push(file)

        this.model.model_files_meta.push({
          id: this.model.metadata.cash_io ?? null,
          name: 'Cash Order IO',
          source_name: `${file_name}.pdf`,
          description: 'Cash Order IO',
          size: file.size,
          type: 'application/pdf',
        })

        this.model.preUploadFiles().then((response: any) => {
          const files = response.data.result.files

          this.model.model_files_meta = this.model.model_files_meta.map((meta: any) => {
            for (let key in files) {
              if (meta.source_name === key) {
                meta.tmp_source = files[key]
                break
              }
            }
            return meta
          })

          this.confirming_order = true
          this.model.metadata.invoice.billing_info_source = 'agency'
          this.model.status = 'confirmed'
          this.import_status = 'done'
          this.theForm.formWizard.reset()
        })
      })
    }

    reader.readAsArrayBuffer(file)
  }

  public bulkDelete() {
    EventBus.$emit('bulk-delete', true)
  }
}
