import layout from '@/mixins/layout'
import countryCodes from '@/data/country_options'
import stateCodes from '@/data/state_options'
import Api from '@/models/Api'
import {
  MediaPlanTypes,
  MediaPlanViewColumns,
  capitalize,
  MediaPlanViewColumnsLinear,
  BillingCycle,
  InvoiceUploadMode,
  InvoicePaymentTerms,
  InvoiceBillingTarget,
  AgencyFeeModel,
  OptionalOpportunityAgencyIds,
} from '@/models/interface/Common'
import { currencyMask, percentageMask } from '@/models/interface/Masks'
import MediaPlan from '@/models/MediaPlan'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'
import Company from '@/models/Company'
import { clone as _clone } from 'lodash'
import moment from 'moment'
import WebMessage from '@/models/WebMessage'
import MediaPlanItem from '@/models/MediaPlanItem'
import dmas from '@/data/dmas'

const dmasStationOptions = dmas.map(d => ({
  name: `SS${d.media_ocean_station} (${d.name})`,
  value: `SS${d.media_ocean_station}`,
}))
const { user } = getModule(SystemtModule)

export default {
  name: 'Media Plan',
  submitText: 'Save & Close',
  actions: [
    {
      name: 'Save & Download Proposal XML',
      icon: 'download',
      action: 'saveDownload',
      visible: (media_plan: MediaPlan) => media_plan.isLinear,
    },
  ],
  sections: [
    {
      name: 'Campaign Information',
      icon: 'user',
      actions: [
        {
          name: 'Import from Order File',
          icon: 'upload',
          action: 'import-file',
        },
      ],
      validation: (media_plan: MediaPlan) =>
        new Promise((resolve, reject) => {
          if (media_plan.metadata.demo_targets.length <= 0) {
            return reject(new Error('Please add at least one demo group.'))
          }
          media_plan.metadata.demo_targets.forEach((target: any) => {
            if (target.age_high !== '+' && target.age_low > target.age_high) {
              return reject(new Error('Demo Age range is invalid.'))
            }
          })
          if (media_plan.isLinear) {
            media_plan.metadata.invoice.billing_info_source = media_plan.agency_id
              ? 'agency'
              : 'advertiser'
          } else if (!media_plan.agency_id) {
            media_plan.metadata.invoice.billing_info_source = 'advertiser'
          }
          resolve('')

          if (media_plan.line_items.length === 0) {
            media_plan.addLineItem()
          }
        }),
      fields: [
        {
          name: 'Opportunity',
          type: 'opportunity-picker',
          target: 'opportunity_id',
          visible: (media_plan: MediaPlan) =>
            !(
              media_plan.isLinear
              || (media_plan.agency_id && OptionalOpportunityAgencyIds.includes(media_plan.agency_id))
            ),
          rules: 'required',
        },
        {
          name: 'Opportunity',
          type: 'opportunity-picker',
          target: 'opportunity_id',
          visible: (media_plan: MediaPlan) =>
            media_plan.isLinear
            || (media_plan.agency_id && OptionalOpportunityAgencyIds.includes(media_plan.agency_id)),
        },
        {
          name: 'Plan Type',
          type: 'picker',
          target: 'formType',
          rules: 'required',
          options: MediaPlanTypes,
        },
        {
          name: 'Order Name',
          type: 'text',
          target: 'name',
          rules: 'required|min:3|max:255',
        },
        {
          name: 'Agency',
          type: 'agency',
          rules: 'required',
          target: 'agency_id',
        },
        {
          name: 'Station',
          type: 'station',
          target: 'station_id',
          sub_target: 'agency_id',
          visible: (media_plan: MediaPlan) =>
            media_plan.agency_id === '8e3e40d3-d1b5-484e-a56e-22cab7386941',
        },
        {
          name: 'Agency Office',
          type: 'station',
          target: 'station_id',
          sub_target: 'agency_id',
          visible: (media_plan: MediaPlan) =>
            media_plan.agency_id !== '8e3e40d3-d1b5-484e-a56e-22cab7386941',
        },
        {
          name: 'Advertiser',
          type: 'advertiser',
          target: 'advertiser_id',
          rules: 'required|min:3|max:255',
        },
        {
          name: 'Station Call Letters',
          type: 'picker',
          target: 'formStationCallLetters',
          rules: 'required',
          options: dmasStationOptions,
          visible: (media_plan: MediaPlan) => media_plan.isLinear,
        },
        {
          name: 'Is DV Station?',
          type: 'checkbox',
          target: 'metadata.station.is_dv',
          visible: (media_plan: MediaPlan) => media_plan.type == 'strata',
          description: 'Includes "-DV" in the XML',
        },
        {
          name: 'Ad Buyer Company Name',
          type: 'text',
          target: 'contact_company',
          rules: 'required',
        },
        {
          name: 'Ad Buyer Contact Name',
          type: 'text',
          target: 'contact_name',
          rules: 'required',
        },
        {
          name: 'Ad Buyer Contact Title',
          type: 'text',
          target: 'contact_title',
        },
        {
          name: 'Ad Buyer Contact Email',
          type: 'text',
          target: 'contact_email',
        },
        {
          name: 'Sales Management',
          type: 'user',
          target: 'sales_management_id',
        },
        {
          name: 'Sales Rep',
          type: 'user',
          rules: 'required',
          target: 'sales_rep_id',
        },
        {
          name: 'Secondary Sales Reps',
          type: 'user',
          multiple: true,
          allow_empty: true,
          rules: '',
          target: 'secondary_sales_rep_ids',
        },
        {
          name: 'Account Manager',
          type: 'user',
          target: 'account_manager_id',
        },
        {
          name: 'Agency Comission Model',
          type: 'picker',
          target: 'formAgencyCommissionModel',
          options: AgencyFeeModel,
          rules: 'required',
        },
        {
          name: 'Agency Comission',
          type: 'number',
          target: 'formAgencyCommission',
          mask: percentageMask,
          rules: 'required',
          visible: (media_plan: MediaPlan) => media_plan.agency_commission_model === 'percentage',
        },
        {
          name: 'Agency Comission',
          type: 'number',
          target: 'formAgencyCommission',
          mask: currencyMask,
          rules: 'required',
          visible: (media_plan: MediaPlan) => media_plan.agency_commission_model === 'cpm',
        },
        {
          name: 'Billing Cycle',
          type: 'picker',
          target: 'metadata.billing_cycle',
          options: BillingCycle,
          rules: 'required',
          visible: (media_plan: MediaPlan) => media_plan.status !== 'draft',
        },
        {
          name: 'Show Media Plan Columns',
          type: 'picker',
          target: 'metadata.view_columns',
          options: MediaPlanViewColumns,
          multiple: true,
          visible: (media_plan: MediaPlan) => !media_plan.isLinear,
        },
        {
          name: 'Show Media Plan Columns',
          type: 'picker',
          target: 'metadata.view_columns',
          options: MediaPlanViewColumnsLinear,
          multiple: true,
          visible: (media_plan: MediaPlan) => media_plan.isLinear,
        },
        {
          name: 'Terms',
          type: 'terms',
          sub_type: 'advertiser',
          target: 'formTermsId',
          sub_target: 'formTerms',
          visible: (media_plan: MediaPlan) => media_plan.formType === 'default',
        },
        {
          name: 'Demo Groups',
          type: 'demo_builder',
          target: 'formDemos',
          visible: (media_plan: MediaPlan) => media_plan.formType !== 'default',
        },
      ],
    },
    {
      name: (media_plan: MediaPlan) =>
        `${capitalize(media_plan.type.replaceAll('_', ' '))} Details`,
      visible: (media_plan: MediaPlan) =>
        media_plan.status !== 'draft' && media_plan.type !== 'default',
      icon: 'receipt',
      actions: [
        {
          name: 'Import from Order File',
          icon: 'upload',
          action: 'import-file',
        },
      ],
      validation: (media_plan: MediaPlan) =>
        new Promise((resolve, reject) => {
          // Check start & end date
          let start_date = moment(media_plan.metadata.header.start_date, 'YYMMDD')
          let end_date = moment(media_plan.metadata.header.end_date, 'YYMMDD')
          if (start_date.isAfter(end_date)) {
            return reject(new Error('Start date must be before end date.'))
          }

          resolve('')
        }),
      fields: [
        {
          name: 'Agency (Code 21)',
          type: 'hr',
        },
        {
          name: 'IDB number (ID 2)',
          type: 'text',
          target: 'metadata.agency.id',
          rules: 'required|max:8',
          description: 'Agency name from IDB list',
          onBlur: (media_plan: MediaPlan) => {
            const api = new Api()
            api
              .get(`media_plan/upload_mode/${media_plan.metadata.agency.id}?agency_uuid=${media_plan.agency_id}`)
              .then((response: any) => {
                media_plan.metadata.invoice_mode = response.data.result.upload_mode
                if (
                  response.data.result.name
                  && (!media_plan.metadata.agency.name || media_plan.metadata.agency.name === '')
                ) {
                  media_plan.metadata.agency.name = response.data.result.name
                }
                if (
                  response.data.result.address
                  && (!media_plan.metadata.agency.address_line_1
                    || media_plan.metadata.agency.address_line_1 === '')
                ) {
                  media_plan.metadata.agency.address_line_1 = response.data.result.address[0] ?? ''
                  media_plan.metadata.agency.address_line_2 = response.data.result.address[1] ?? ''
                  media_plan.metadata.agency.address_line_3 = response.data.result.address[2] ?? ''
                  media_plan.metadata.agency.address_line_4 = response.data.result.address[3] ?? ''
                }
              })
          },
        },
        {
          name: 'Agency Name (ID 3)',
          type: 'text',
          target: 'metadata.agency.name',
          rules: 'required|max:25',
        },
        // {
        //   name: 'Address Line 1 (ID 4)',
        //   type: 'text',
        //   target: 'metadata.agency.address_line_1',
        //   rules: 'required|max:30',
        // },
        // {
        //   name: 'Address Line 2 (ID 5)',
        //   type: 'text',
        //   target: 'metadata.agency.address_line_2',
        //   rules: 'max:30',
        // },
        // {
        //   name: 'Address Line 3 (ID 6)',
        //   type: 'text',
        //   target: 'metadata.agency.address_line_3',
        //   rules: 'max:30',
        // },
        // {
        //   name: 'Address Line 4 (ID 7)',
        //   type: 'text',
        //   target: 'metadata.agency.address_line_4',
        //   rules: 'max:30',
        // },
        {
          name: 'ISCI required for Invoice?',
          type: 'checkbox',
          target: 'metadata.isci_required',
        },
        {
          name: 'Station (Code 22)',
          type: 'hr',
        },
        {
          name: 'Station Call Letters (ID 2)',
          type: 'picker',
          target: 'metadata.station.call_letters',
          options: dmasStationOptions,
          rules: 'required',
        },
        {
          name: 'Header (Code 31)',
          type: 'hr',
        },
        {
          name: 'Order Total Cash ',
          type: 'number',
          mask: currencyMask,
          target: 'metadata.order_total_cash',
          rules: 'required',
          visible: (media_plan: MediaPlan) => media_plan.orderCashAndTradeIsRequired,
        },
        {
          name: 'Order Total Cash ',
          type: 'number',
          mask: currencyMask,
          target: 'metadata.order_total_cash',
          visible: (media_plan: MediaPlan) => !media_plan.orderCashAndTradeIsRequired,
        },
        {
          name: 'Order Total Trade ',
          type: 'number',
          mask: currencyMask,
          target: 'metadata.order_total_trade',
          rules: 'required',
          visible: (media_plan: MediaPlan) => media_plan.orderCashAndTradeIsRequired,
        },
        {
          name: 'Order Total Trade ',
          type: 'number',
          mask: currencyMask,
          target: 'metadata.order_total_trade',
          visible: (media_plan: MediaPlan) => !media_plan.orderCashAndTradeIsRequired,
        },
        {
          name: 'Representative (ID 2)',
          type: 'text',
          target: 'metadata.header.representative',
          rules: 'max:25',
        },
        {
          name: 'Advertiser Name (ID 4)',
          type: 'text',
          target: 'metadata.header.advertiser_name',
          rules: 'required|max:25',
          description: "List as 'Client' appears on IO",
        },
        {
          name: 'Product Name (ID 5)',
          type: 'text',
          target: 'metadata.header.product_name',
          rules: 'required|max:25',
        },
        {
          name: 'Agency Estimate Code (ID 8)',
          type: 'text',
          target: 'metadata.header.agency_estimate_code',
          rules: 'between:1,9999',
          description: 'Not required but strongly recommended.',
          visible: (media_plan: MediaPlan) => media_plan.type === 'media_ocean',
        },
        {
          name: 'Agency Estimate Code (ID 8)',
          type: 'text',
          target: 'metadata.header.agency_estimate_code',
          rules: 'max:10',
          description: 'Not required but strongly recommended.',
          visible: (media_plan: MediaPlan) => media_plan.type === 'strata',
        },
        {
          name: 'Period Start Date (ID 11)',
          type: 'text',
          target: 'metadata.header.start_date',
          rules: 'max:6',
          description: 'Include period dates from IO (YYMMDD)',
        },
        {
          name: 'Period End Date (ID 12)',
          type: 'text',
          target: 'metadata.header.end_date',
          rules: 'max:6',
          description: 'Include period dates from IO (YYMMDD)',
        },
        {
          name: 'Station Cash Order Number (ID 23-A)',
          type: 'text',
          target: 'metadata.header.station_order_number',
          rules: 'max:10',
        },
        {
          name: 'Station Trade Order Number (ID 23-B)',
          type: 'text',
          target: 'metadata.header.station_trade_order_number',
          rules: 'max:10',
        },
        {
          name: 'Agency Advertiser Code (ID 25)',
          type: 'text',
          target: 'metadata.header.agency_advertiser_code',
          rules: 'max:8',
          description: 'Not required but strongly recommended.',
        },
        {
          name: 'Agency Product Code (ID 28)',
          type: 'text',
          target: 'metadata.header.agency_product_code',
          rules: 'max:8',
          description: 'Not required but strongly recommended.',
        },
        {
          name: 'Trading Partner Code (ID 32)',
          type: 'text',
          target: 'metadata.header.trading_partner_code',
          rules: 'max:8',
        },
        {
          name: 'National Report LOC for Local (ID 34)',
          type: 'text',
          target: 'metadata.header.rep_id',
          rules: 'max:10',
        },
        {
          name: 'Special Paying Rep Code (ID 40)',
          type: 'text',
          target: 'metadata.header.special_paying_rep_code',
          rules: 'max:3',
        },
        {
          name: 'Invoicing Mode',
          type: 'picker',
          target: 'metadata.invoice.delivery_driver',
          rules: 'required',
          options: InvoiceUploadMode,
        },
      ],
    },
    {
      id: 'invoice-details',
      name: 'Invoice Details',
      visible: (media_plan: MediaPlan) =>
        media_plan.status !== 'draft' && media_plan.type === 'default',
      icon: 'receipt',
      actions: [
        {
          name: 'Import from Order File',
          icon: 'upload',
          action: 'import-file',
        },
      ],
      validation: (media_plan: MediaPlan) =>
        new Promise((resolve, reject) => {
          if (media_plan.agency) {
            // CHeck billing fields
            if (
              !media_plan.metadata.client_data.name
              && media_plan.agency.billing_info.required_plan_fields.includes('client_name')
            ) {
              return reject(
                new Error('The client name is required for this agency. Please enter it above.'),
              )
            }

            if (
              !media_plan.metadata.client_data.order
              && media_plan.agency.billing_info.required_plan_fields.includes('order')
            ) {
              return reject(
                new Error('The Order is required for this agency. Please enter it above.'),
              )
            }

            if (
              !media_plan.metadata.client_data.estimate
              && media_plan.agency.billing_info.required_plan_fields.includes('estimate')
            ) {
              return reject(
                new Error('The Estimate is required for this agency. Please enter it above.'),
              )
            }

            if (
              !media_plan.metadata.client_data.product
              && media_plan.agency.billing_info.required_plan_fields.includes('product')
            ) {
              return reject(
                new Error('The Product is required for this agency. Please enter it above.'),
              )
            }

            if (
              !media_plan.metadata.client_data.product_estimate
              && media_plan.agency.billing_info.required_plan_fields.includes('product_estimate')
            ) {
              return reject(
                new Error(
                  'The Product Estimate is required for this agency. Please enter it above.',
                ),
              )
            }
          }
          if (!media_plan.info_acknolwedged) {
            return reject(
              new Error('Please review the billing information and confirm that it is correct.'),
            )
          }
          resolve('')
        }),
      fields: [
        {
          name: 'Order Net Total',
          type: 'number',
          mask: currencyMask,
          target: 'metadata.order_total_cash',
          rules: 'required',
          visible: (media_plan: MediaPlan) =>
            !OptionalOpportunityAgencyIds.includes(media_plan.agency_id ?? ''),
        },
        {
          name: 'Payment Terms',
          type: 'picker',
          target: 'metadata.invoice.payment_terms',
          rules: 'required',
          options: InvoicePaymentTerms,
        },
        {
          name: 'Client Name',
          type: 'text',
          target: 'metadata.client_data.name',
          rules: 'max:255',
        },
        {
          name: 'Order',
          type: 'text',
          target: 'metadata.client_data.order',
          rules: 'max:255',
        },
        {
          name: 'Estimate',
          type: 'text',
          target: 'metadata.client_data.estimate',
          rules: 'max:255',
        },
        {
          name: 'Product',
          type: 'text',
          target: 'metadata.client_data.product',
          rules: 'max:255',
        },
        /* {
          name: 'Product Estimate',
          type: 'text',
          target: 'metadata.client_data.product_estimate',
          rules: 'max:255',
        }, */
        {
          name: 'Billing Target',
          type: 'picker',
          target: 'metadata.invoice.billing_info_source',
          rules: 'required',
          edit: (media_plan: MediaPlan) => !!media_plan.agency_id,
          options: InvoiceBillingTarget,
        },
        {
          name: 'Billing Details',
          type: 'hr',
          visible: (media_plan: MediaPlan) => !!media_plan.metadata.invoice.billing_info_source,
          actions: [
            {
              title: (media_plan: MediaPlan) =>
                (media_plan.metadata.invoice.billing_custom
                  ? 'Use Default Billing Information'
                  : 'Use Custom Billing Information'),
              icon: (media_plan: MediaPlan) =>
                (media_plan.metadata.invoice.billing_custom ? 'undo' : 'pen'),
              action: (media_plan: MediaPlan) => {
                if (!media_plan.metadata.invoice.billing_custom && media_plan.billing_client_id) {
                  Company.find(media_plan.billing_client_id).then(c => {
                    if (c) media_plan.metadata.invoice.billing_info = _clone(c.billing_info)
                  })
                }
                media_plan.metadata.invoice.billing_custom = !media_plan.metadata.invoice.billing_custom
              },
            },
          ],
        },
        {
          name: 'Client Billing Information',
          type: 'company_billing_information',
          visible: (media_plan: MediaPlan) =>
            media_plan.metadata.invoice.billing_info_source === 'agency'
            && !media_plan.metadata.invoice.billing_custom,
          target: 'agency_id',
          sub_target: 'info_acknolwedged',
          sub_target_2: 'billing_custom',
        },
        {
          name: 'Client Billing Information',
          type: 'company_billing_information',
          visible: (media_plan: MediaPlan) =>
            media_plan.metadata.invoice.billing_info_source === 'advertiser'
            && !media_plan.metadata.invoice.billing_custom,
          target: 'advertiser_id',
          sub_target: 'info_acknolwedged',
        },
        {
          name: 'Client Billing Information',
          type: 'company_billing_information',
          visible: (media_plan: MediaPlan) =>
            media_plan.metadata.invoice.billing_info_source === 'station'
            && !media_plan.metadata.invoice.billing_custom,
          target: 'station_id',
          sub_target: 'info_acknolwedged',
        },
        {
          name: 'Country',
          type: 'picker',
          target: 'metadata.invoice.billing_info.country',
          rules: 'required',
          options: countryCodes,
          visible: (media_plan: MediaPlan) => media_plan.metadata.invoice.billing_custom,
        },
        {
          name: 'State',
          type: 'picker',
          target: 'metadata.invoice.billing_info.state',
          rules: 'required',
          options: stateCodes,
          visible: (media_plan: MediaPlan) => media_plan.metadata.invoice.billing_custom,
        },
        {
          name: 'Zip Code',
          type: 'text',
          target: 'metadata.invoice.billing_info.zipcode',
          rules: 'required|max:255',
          visible: (media_plan: MediaPlan) => media_plan.metadata.invoice.billing_custom,
        },
        {
          name: 'Address Line 1',
          type: 'text',
          target: 'metadata.invoice.billing_info.address_line_1',
          rules: 'required|max:255',
          visible: (media_plan: MediaPlan) => media_plan.metadata.invoice.billing_custom,
        },
        {
          name: 'Address Line 2',
          type: 'text',
          target: 'metadata.invoice.billing_info.address_line_2',
          rules: 'max:255',
          visible: (media_plan: MediaPlan) => media_plan.metadata.invoice.billing_custom,
        },
        {
          name: 'Address Line 3',
          type: 'text',
          target: 'metadata.invoice.billing_info.address_line_3',
          rules: 'max:255',
          visible: (media_plan: MediaPlan) => media_plan.metadata.invoice.billing_custom,
        },
        {
          name: 'Address Line 4',
          type: 'text',
          target: 'metadata.invoice.billing_info.address_line_4',
          rules: 'max:255',
          visible: (media_plan: MediaPlan) => media_plan.metadata.invoice.billing_custom,
        },
        {
          name: 'Billing Emails',
          type: 'custom_picker',
          target: 'metadata.invoice.billing_info.billing_email',
          visible: (media_plan: MediaPlan) =>
            media_plan.metadata.invoice.billing_custom
            && media_plan.metadata.invoice.delivery_driver !== 'email',
        },
        {
          name: 'Billing Emails',
          type: 'custom_picker',
          sub_type: 'email',
          target: 'metadata.invoice.billing_info.billing_email',
          rules: 'required',
          visible: (media_plan: MediaPlan) =>
            media_plan.metadata.invoice.billing_custom
            && media_plan.metadata.invoice.delivery_driver === 'email',
        },
        {
          name: 'Billing Phone',
          type: 'text',
          target: 'metadata.invoice.billing_info.billing_phone',
          rules: 'max:255',
          visible: (media_plan: MediaPlan) => media_plan.metadata.invoice.billing_custom,
        },
      ],
    },
    {
      name: 'Plan Builder',
      icon: 'list',
      validation: (media_plan: MediaPlan) =>
        new Promise((resolve, reject) => {
          media_plan.line_items.forEach((item: MediaPlanItem) => {
            if (!item.media_package_id) {
              item._showDetails = true
              return reject(
                new Error(`Please fill in the media package on Line Item #${item.number}`),
              )
            }
            let start = moment(item.start_at)
            let end = moment(item.end_at)

            if (start.isAfter(end)) {
              item._showDetails = true
              return reject(
                new Error(`End date must be after than start date on Line Item #${item.number}`),
              )
            }

            if (media_plan.isLinear) {
              if (!item.creative_length) {
                item._showDetails = true
                return reject(
                  new Error(`Please fill in the creative length on Line Item #${item.number}`),
                )
              }
              if (!item.media_package_id) {
                item._showDetails = true
                return reject(
                  new Error(`Please fill in the media package on Line Item #${item.number}`),
                )
              }
              if (!item.metadata.program_name) {
                item._showDetails = true
                return reject(
                  new Error(`Please fill in the program name on Line Item #${item.number}`),
                )
              }
              if (!item.metadata.flight_time.start_at) {
                item._showDetails = true
                return reject(
                  new Error(`Please fill in the start time on Line Item #${item.number}`),
                )
              }
              if (!item.metadata.flight_time.end_at) {
                item._showDetails = true
                return reject(new Error(`Please fill in the end time on Line Item #${item.number}`))
              }
              if (
                item.impressions > 0
                && item.metadata.spots.reduce((acc: number, spot: number) => acc + Number(spot), 0)
                  <= 0
              ) {
                item._showDetails = true
                return reject(
                  new Error(`Please add at least one spot on Line Item #${item.number}`),
                )
              }

              if (item.metadata.days.length <= 0) {
                item._showDetails = true
                return reject(
                  new Error(`Please add at least one day of the week on Line Item #${item.number}`),
                )
              }
            }

            if (item.product === 'ccl') {
              let { revenue_schedule_total } = item.schedule_exceeds

              if (revenue_schedule_total) {
                item._showDetails = true
                return reject(
                  new Error(
                    `Revenue Schedule does not match the net total on Line Item ${item.name}`,
                  ),
                )
              }
            }
          })

          const cash_mismatch = media_plan.cashDiff > 0.05
          const trade_mismatch = media_plan.tradeDiff > 0.05

          if (
            media_plan.orderCashAndTradeIsRequired
            && (cash_mismatch || trade_mismatch)
            && !OptionalOpportunityAgencyIds.includes(media_plan.agency_id ?? '')
          ) {
            let msg = ''
            if (cash_mismatch && trade_mismatch) {
              msg = 'The Media Plan Total does not match the Cash and Trade Totals.'
            } else if (cash_mismatch) {
              msg = 'The Media Plan Total does not match the Cash Total.'
            } else if (trade_mismatch) {
              msg = 'The Media Plan Total does not match the Trade Total.'
            }

            if (!media_plan.isLinear) {
              msg = 'The Media Plan Total does not match the Net Total.'
            }

            return reject(new Error(msg))
          }

          if (media_plan.isLinear) {
            let allItemsStartOnMonday = media_plan.line_items.every(item =>
              item.metadata.days.includes('monday'))
            let allItemsHaveFullWeeks = media_plan.line_items.every(
              item => item.metadata.days.length === 7,
            )

            let potentialIncorrectTrade = media_plan.line_items.filter(
              item =>
                item.name.toLowerCase().includes('trade') && item.metadata.order_type !== 'trade',
            )

            if (allItemsHaveFullWeeks) {
              WebMessage.doubleConfirm(
                'All Line Items are set to run for a full week, are you sure that this is correct?',
                'Please verify the days of the week',
                'Yes, all Line Items should run for a full week',
              ).then((result: boolean) => {
                if (result) {
                  resolve('')
                }
              })

              return
            }
            if (allItemsStartOnMonday) {
              WebMessage.doubleConfirm(
                'All Line Items are set to start on Monday, are you sure that this is correct?',
                'Please verify the days of the week',
                'Yes, all Line Items should start on Monday',
              ).then((result: boolean) => {
                if (result) {
                  resolve('')
                }
              })

              return
            }

            if (media_plan.advertiser?.industry === 'automotive') {
              let flight_months: number[] = []

              media_plan.line_items.forEach(item => {
                let month = moment(item.start_at).month()
                if (!flight_months.includes(month)) {
                  flight_months.push(month)
                }
              })

              let first_cash_items: MediaPlanItem[] = []
              let first_trade_items: MediaPlanItem[] = []

              flight_months.forEach(month => {
                let first_cash: MediaPlanItem | null = null
                let first_trade: MediaPlanItem | null = null
                media_plan.line_items.forEach(item => {
                  if (moment(item.start_at).month() === month) {
                    let start = moment(item.start_at)

                    if (
                      (!first_cash || start.isBefore(moment(first_cash.start_at)))
                      && item.metadata.order_type === 'cash'
                    ) {
                      first_cash = item
                    }

                    if (
                      (!first_trade || start.isBefore(moment(first_trade.start_at)))
                      && item.metadata.order_type === 'trade'
                    ) {
                      first_trade = item
                    }
                  }
                })

                if (first_cash) {
                  first_cash_items.push(first_cash)
                }

                if (first_trade) {
                  first_trade_items.push(first_trade)
                }
              })
              let hasItemsStartingOnFirstMondayOfMonth = first_cash_items.some(item => item.metadata.days.includes('monday'))
                || first_trade_items.some(item => item.metadata.days.includes('monday'))

              if (hasItemsStartingOnFirstMondayOfMonth) {
                WebMessage.doubleConfirm(
                  'Some Line Items are set to start on Monday, usually Automotive campagins do not start on Mondays. Are you sure that this is correct?',
                  'Please verify the days of the week',
                  'Yes, some Line Items should start on Monday',
                ).then((result: boolean) => {
                  if (result) {
                    resolve('')
                  }
                })

                return
              }
            }

            if (potentialIncorrectTrade.length > 0) {
              WebMessage.doubleConfirm(
                'Some Line Items have the word "trade" in the name but are not set to trade type. Are you sure that this is correct?',
                'Please verify the order type',
                'Yes, all line items are correct',
              ).then((result: boolean) => {
                if (result) {
                  resolve('')
                }
              })

              return
            }
          }

          resolve('')
        }),
      actions: [
        {
          name: 'Batch Delete',
          icon: 'trash-alt',
          action: 'bulk-delete',
        },
        /* {
          name: 'Expand/Compact View',
          icon: () => {
            const { view_mode } = getModule(LayoutModule)
            return view_mode === 'compact' ? 'expand' : 'compress'
          },
          action: () => {
            const layoutModule = getModule(LayoutModule)
            layoutModule.toggleViewMode()
          },
        }, */
        {
          name: 'Import from Order File',
          icon: 'upload',
          action: 'import-file',
        },
        {
          name: 'Toggle All',
          icon: (model: MediaPlan) => (model.line_items[0]._showDetails ? 'eye-slash' : 'eye'),
          action: (model: MediaPlan) => {
            const state = model.line_items[0]._showDetails
            model.line_items.forEach(item => {
              item._showDetails = !state
            })
          },
        },
        {
          name: 'Save (CTRL+S)',
          icon: 'save',
          action: 'save',
        },
        {
          name: 'Generate Trade Line Item',
          icon: 'calendar-plus',
          action: 'generate-trade',
          visible: (model: MediaPlan) => model.isLinear,
        },
        {
          name: 'Batch Edit',
          icon: 'edit',
          action: 'batch',
        },
        {
          name: 'Add Line Item',
          icon: 'plus',
          action: (model: MediaPlan) => model.addLineItem(),
        },
      ],
      fields: [
        {
          name: 'Line Items',
          type: 'media_plan_builder',
        },
      ],
    },

    {
      name: 'Billing Schedule',
      icon: 'hand-holding-usd',
      slot: true,
      slot_name: 'billing_schedule',
      visible: (model: MediaPlan) => model.contains_ccl_product,

      validation: (media_plan: MediaPlan) =>
        new Promise((resolve, reject) => {
          let total_ccl = media_plan.line_items.reduce((acc: number, item: MediaPlanItem) => {
            if (item.product === 'ccl') {
              return acc + item.net_cost
            }
            return acc
          }, 0)

          if (total_ccl !== media_plan.billing_schedule_total) {
            return reject(new Error('The billing schedule does not match the total CCL amount.'))
          }

          resolve('')
        }),
      actions: [
        {
          name: 'Import from Order File',
          icon: 'upload',
          action: 'import-file',
        },
      ],
    },
  ],
}
